<template>
  <div id="customer_management">
    <!-- 리스트 -->
    <v-data-table
      class="client-list"
      :class="{ 'mac': is_mac, }"
      :height="height"
      :headers="headers"
      :items="data"
      :items-per-page="-1"
      :disable-pagination="true"
      :footer-props="{
        disablePagination: true,
        disableItemsPerPage: true,
      }"
      :sort-by.sync="sort.sort_by"
      :sort-desc.sync="sort.sort_desc"
      fixed-header hide-default-footer hide-default-header
    >

      <template v-slot:header="{props}">
        <thead class="v-data-table-header custom-header" :class="{ 'mac': is_mac, }">
          <tr>
            <th class="column-spacer"></th>
            <th
              v-for="head in props.headers"
              :key="head.name"
              class="header"
              :style="{ 
                'width': head.width, 
                'text-align': head.align, 
              }"
            >
              <span class="header-text" >{{ head.text }}</span>
              <v-btn 
                v-if="head.sortable"
                class="header__sort-btn rounded-0" 
                :class="{ 
                  'sort--active': sort.sort_by == head.value,
                  'sort--desc': sort.sort_desc,
                }"
                :ripple="false"
                @click="tableSort(head.value)"
                fab x-small text plain
              >
                <v-icon size="12">$sort</v-icon>
              </v-btn>
            </th>
            <th class="column-spacer end"></th>
          </tr>
        </thead>
      </template>

      <template v-slot:item="props">
        <tr>
          <td class="column-spacer"></td>
          <td class="table-column text-left">
            {{ props.item.name }}
          </td>
          <td class="table-column text-left">
            {{ props.item.manager }}
          </td>
          <td class="table-column text-left">
            {{ props.item.phone }}
          </td>
          <td class="table-column text-center actions">
            <v-btn 
              class="line-action line-modify"
              fab depressed x-small outlined light
              @click="modifyLine(props.item)"
            >
              <v-icon size="16">$modify</v-icon>
            </v-btn>
            <v-btn 
              class="line-action line-delete"
              fab depressed x-small outlined light
              @click="confirmDeleteLine(props.item)"
            >
              <v-icon size="16">$deleteIcon</v-icon>
            </v-btn>
          </td>
          <td class="column-spacer end"></td>
        </tr>
      </template>

    </v-data-table>
    <!-- 입력 폼 -->
    <div
      class="insert-panel"
      :class="{ 'insert-form--add-mode': add_row.mode }"
    >
      <v-btn 
        class="add-line-btn" 
        :class="{ 'add-line-btn--inactive': !add_row.mode, }"
        @click="turnAddLine"
        :ripple="false"
        fab depressed
      >
        <v-icon size="22">$sort</v-icon>
      </v-btn>

      <v-form 
        ref="form"
        class="insert__form"
        :class="{ 
          'insert__form--inactive': !add_row.mode,
          'mac': is_mac,
        }"
        v-model="valid" 
        lazy-validation
      >  
        <p class="form-content">
          <v-text-field
            class="form__field name rounded-0"
            label="고객사명"
            :rules="nameRule"
            v-model="insertForm.name"
            @keyup.enter="enter"
            solo flat
            hide-details required
          ></v-text-field>
          <v-text-field
            class="form__field manager rounded-0"
            label="담당자"
            v-model="insertForm.manager"
            :rules="managerRule"
            @keyup.enter="enter"
            solo flat
            hide-details required
          ></v-text-field>
          <v-text-field
            class="form__field phone rounded-0"
            label="연락처"
            v-model="insertForm.phone"
            :rules="phoneRule"
            @keyup.enter="enter"
            solo flat
            hide-details required
          ></v-text-field>
        </p>

        <div class="form__actions" :class="{ 'mac': is_mac, }">
          <v-btn 
            class="action-btn line-add-btn"
            v-if="add_row.type == 'add'"
            :disabled="!valid"
            @click="insertData"
            medium depressed
          >추가</v-btn>
          <v-btn 
            class="action-btn line-modify-btn"
            medium depressed
            v-if="add_row.type == 'modify'"
            @click="modifyData"
          >수정</v-btn>
          <v-btn 
            class="action-btn line-cancle-btn"
            medium depressed
            v-if="add_row.type == 'modify'"
            @click="modifyLineCancle"
          >취소</v-btn>
        </div>
      </v-form>
    </div>

    <v-dialog 
      content-class="delete-confirm"
      v-model="delete_line.open"
      dark
    >
      <v-card class="inner">
        <div class="dialog__content">
          <div class="dialog__confirm-text">
            <strong>{{ delete_line.name }}</strong>(을)를<br>
            정말 삭제하시겠습니까?
          </div>
        </div>
        <v-card-actions class="dialog__actions" :class="{ 'mac': is_mac, }">
          <v-btn 
            class="action-btn"
            @click="delete_line.open = false"
            light depressed
          >취소</v-btn>
          <v-btn 
            class="action-btn"
            @click="deleteLine"
            light depressed
          >삭제</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      height: 500,
      valid:true,
      headers: [
        {
          text: '고객사명',
          value: 'name',
          align: 'left',
          sortable: true, 
        },
        {
          text: '담당자',
          value: 'manager',
          align: 'left',
          sortable: true, 
          width: '20%',
        },
        {
          text: '연락처',
          value: 'phone',
          align: 'left',
          sortable: false, 
          width: '20%',
        },
        {
          text: '관리',
          value: 'actions',
          align: 'center',
          sortable: false, 
          width: '160px',
        }
      ],
      data: [
      ],
      insertForm: {
        name: null,
        phone:null,
        manager:null,
      },
      add_row: {
        mode: true,
        type: 'add',
      },
      delete_line: {
        open: false,
        id: undefined,
        name: '',
      },
      sort: {
        sort_by: '',
        sort_desc: false,
      },
      nameRule: [
        v => !!v || '고객사이름이 필요합니다.'
      ],
      managerRule: [
        v => !!v || '담당자가 필요합니다.'
      ],
      phoneRule: [
        v => !!v || '담당자가 필요합니다.'
      ],
    };
  },
  watch:{
    valid:function(v){
      console.log(v);
    }
  },
  mounted() {
    this.listHeight();
    document.documentElement.style.overflowY = 'hidden';
    window.addEventListener('resize', this.animationFrame(this.listHeight));
    this.selb();
  },
  methods: {
    listHeight() {
      let hei = window.innerHeight;
      let except = {
        header: 64,
        extra: 5,
      };
      this.height = hei - except.header - except.extra;
    },
    turnAddLine() { // 입력줄 열고 닫기
      this.add_row.mode = !this.add_row.mode;
    },
    insertData() { // 추가 완료
     if(this.$refs.form.validate()){
      this.$http.APS.post('/order/buyer',this.insertForm).then(r=>{
        this.selb();
      });
      this.addRowReset();
     }
    },
    confirmDeleteLine(item) {
      const del = this.delete_line;
      del.open = true;
      del.id = item.id;
      del.name = item.name;
    },
    deleteLine() { // 선택한 줄 삭제
      const del = this.delete_line;
      this.$http.APS.delete('/order/delb/' + del.id).then(r=>{
        this.selb();
      });
      del.open = false;
      this.addRowReset();
    },
    modifyData() { // 수정 완료
      this.$http.APS.patch('/order/setb',this.insertForm).then(r=>{
        this.selb();
      });
      this.addRowReset();
    }, 
    modifyLine(item) { // 수정 버튼 클릭시
      this.add_row.mode = true;
      this.add_row.type = 'modify'
      this.insertForm = Object.assign({}, item);
    },
    modifyLineCancle() { // 수정 취소
      this.addRowReset();
    },
    addRowReset() { // 입력 줄 리셋
      this.insertForm.name=null;
      this.insertForm.manager=null;
      this.insertForm.phone=null;
      this.add_row.type = 'add';
    },
    selb(){
      this.$http.APS.get('/order/box/').then(r=>{
        this.data=r.data;
      });
    },
    enter(){
      console.log(this.insertForm);
      if(this.add_row.type=='modify'){
        this.modifyData();
      }else{
        this.insertData();
      }
    },
    tableSort(value) {
      const sort = this.sort;
      sort.sort_by = value;
      sort.sort_desc = !sort.sort_desc;
    },
    animationFrame(cb) {
      let rAftimeout = null;
      return function() {
        if( rAftimeout ) {
          window.cancelAnimationFrame(rAftimeout);
        }
        rAftimeout = window.requestAnimationFrame(function() { cb(); });
      };
    },
  },
  computed: {
    is_mac() {
      return this.$is_mac;
    },
  },
  destroyed() {
    document.removeEventListener('resize', this.animationFrame);
  },
}
</script>

<style lang="scss" scoped>

  // 리스트
  ::v-deep .client-list {
    @extend .data-table--skyblue-theme;

    .v-data-table__wrapper {
      padding-bottom: 80px !important;

      .column-spacer {
        width: 250px;

        @include relative-web1-1 {
          width: 32px;

          &.end {
            width: 0;
          }
        }
      }
    }

    .line-action {
      width: 32px;
      height: 32px;
      border: 1px solid black !important;
      margin-right: 10px;
      
      &:last-child { 
        margin-right: 0;
      }

      .cls-1 {
        fill: black;
      }
    }
  }

  // 입력 폼
  .insert-panel {
    width: 100%;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.2);
    padding: 16px 16px 16px 100px;
    position: fixed;
    left: 0;
    bottom: 0;
    background: white;

    &.insert-form--add-form {

      .add-line-btn {
        top: 50%;
      }
    }

    .add-line-btn {
      width: 48px;
      height: 48px;
      position: absolute;
      left: 26px;
      top: 50%;
      transform: translateY(-50%);
      transition: top 0.3s, translate 0.3s;
      background: $skyblue-darken1 !important;

      &::before { 
        opacity: 0;
        display: none !important; 
      }
      &:hover {

        &::before { 
          display: block !important; 
          opacity: 0.087;
        }
      }
      &.add-line-btn--inactive {
        left: 50%;
        top: 0;
        transform: translate(0, -50%);

        ::v-deep .v-icon {
          transform: rotate(-180deg);
          bottom: 1px;
          transition: all 0.3s;
        }
      }

      ::v-deep .v-icon {
        position: relative;
        bottom: -1px;
        transition: all 0.3s;

        .cls-1 { fill: white; }
      }
    }

    .insert__form {
      @extend .flex_row;

      width: 100%;
      max-height: 800px;
      justify-content: stretch;
      transition: max-height .3s;
      overflow-y: hidden;

      &.insert__form--inactive {
        max-height: 0;
        transition: max-height .3s;
      }
      &.mac {
        
        ::v-deep .form__field {

          .v-label {
            
            padding-top: 2px;
          }
        }
      }

      .form-content {
        @extend .flex_row;

        justify-content: stretch;
        flex: 1 1 auto;
      }
      ::v-deep .form__field {
        font-size: 20px !important;
        font-weight: 400;
        margin-right: 16px;
        background-color: #F2F7F9 !important;

        .v-input__slot {
          background-color: transparent !important;
        }
        .v-label {
          font-size: 20px;
        }
      }

      .form__actions {
        flex: 0 0 auto;

        &.mac {

          .action-btn {
            padding-top: 4px;
          }
        }

        .action-btn {
          width: 88px;
          height: 50px;
          border-radius: 6px;
          font-size: 20px;
          font-weight: 400;
          margin-right: 12px;
          background-color: $skyblue-darken1;

          &:last-child {
            margin-right: 0;
          }

          &.line-cancle-btn {
            background-color: white;
            border: 1px solid $skyblue-darken1;
          }
        }
      }
    }
  }


  ::v-deep .delete-confirm {
    @extend .dialog--black-theme;
  }
</style>